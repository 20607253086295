import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../components/layout/seo";
import Metal from "../components/pages/metallo-duro";

const data = {
  intro: (
    <p>
      Il metallo duro con cui è costituito il tagliente, è la parte più
      importante di un utensile.
      <br />
      <br />
      La International Saws, consapevole di tutto ciò, si è adoperata nella
      ricerca del HW più idoneo e disponibile sul mercato, per garantire ai suoi
      utensili migliori prestazioni sul legno, derivati, alluminio, plastica e
      ferro.
    </p>
  ),
  blocks: [
    {
      type: "comparison",
      title: 'Metallo duro "micro-grano"',
      text: (
        <p>
          L'utilizzo di polveri a grana estremamente fine (micrograno) ha reso
          il metallo duro più compatto ed ha quindi diminuito la possibilità di
          abrasione del filo tagliente con conseguente miglioramento della
          qualità di taglio, nonché della durata della lama.
          <br />
          <br />
          Il metallo duro da noi usato conferisce al tagliente la resistenza
          all’abrasione, essenziale per ottenere una buona e duratura qualità di
          taglio.
          <br />
          <br />
          Le lame di altri produttori sono meno resistenti all’abrasione, quindi
          i taglienti si usurano presto e l’utensile dura di meno.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/micgrano-is.png"
          layout="fullWidth"
          alt="i micro-grani delle lame international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/micgrano-comp.png"
          layout="fullWidth"
          alt="i grani delle lame dei competitor"
        />
      ),
    },
    {
      type: "comparison",
      title: "Maggior durata e resistenza",
      text: (
        <p>
          L’aggiunta di Carburo di Titanio alla miscela da sinterizzare ha
          aumentato la durata del tagliente e la sua resistenza alla corrosione
          chimica causata da resine, collanti e composti del legno.
          <br />
          <br />
          L’HW da noi utilizzato è un composito metallico con che si ottiene
          mescolando Carburi di Tungsteno, Titanio ed altri metalli sotto forma
          di polveri, il quale viene pressato ad elevate pressioni, e
          sinterizzato ad alte temperature.
          <br />
          <br />
          Gli altri costruttori di utensili acquistano il metallo duro da vari
          produttori. Non sono in grado di mantenere una qualità costante, né di
          modificare la “grana” dell’HW in base all’utilizzo e ai diversi legni
          da lavorare.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/res-is.png"
          layout="fullWidth"
          alt="il metallo delle lame international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/res-comp.png"
          layout="fullWidth"
          alt="il metallo delle lame dei competitor"
        />
      ),
    },
  ],
};

const MetalloDuro = ({ location }) => (
  <>
    <Seo
      title="Utensili in metallo duro"
      desc="L'utilizzo del Metallo Duro (HW) migliore garantisce ai nostri utensili grandiose prestazioni sul legno, derivati, alluminio, plastica e ferro."
    />
    <Metal data={data} location={location} />
  </>
);

export default MetalloDuro;
